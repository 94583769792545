if (siteConfig.tracking.sentryDsn && tracking.get('sub_id') !== siteConfig.everflow.subId) {
	const Sentry = await import('@sentry/astro');
	Sentry.init({
		dsn: siteConfig.tracking.sentryDsn,
		environment: siteConfig.environment,
		integrations: [
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: true,
			}),
			Sentry.browserTracingIntegration(),
		],
		tracesSampleRate: 1.0,
		tracePropagationTargets: ['localhost', '*-quotestorm.vercel.app', siteConfig.domain],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
	Sentry.setUser({ id: localStorage.userId });
	transactionId().then((id) => Sentry.setTag('transaction_id', id));
	console.log('[Loaded]: Sentry');
}
